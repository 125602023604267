import { createGlobalStyle } from 'styled-components';

const Typography = createGlobalStyle`
  html {
    font-family: 'karla', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    background: var(--black);
    color: var(--white);
  }
  p, li {
    letter-spacing: 0.5px;
    margin: 0;
    font-weight: 400;
  }
  h1,h2,h3,h4,h5,h6 {
    font-weight: bold;
    margin: 0;
  }
  a {
    color: var(--white);
    text-decoration: none;
  }

  .text {

    &--size-normal {
      font-size: 2rem;
    }

    &--size-medium {
      font-size: 2.4rem;

      @media (max-width: 1024px) {
        font-size: 1.6rem;
      }
    }

    &--size-large {
      font-size: 3.6rem;

      @media (max-width: 1024px) {
        font-size: 2.4rem;
      }
    }

    &--size-xlarge {
      font-size: 9.6rem;

      @media (max-width: 1440px) {
        font-size: 7.2rem;
      }

      @media (max-width: 1024px) {
        font-size: 4.8rem;
      }
    }
  }

`;

export default Typography;
