import React, { useEffect, useState } from 'react';

export default function MenuItem({ itemName, active, setIsOpen }) {
  // store our anchorTarget in state
  const [anchorTarget, setAnchorTarget] = useState(null);

  // set anchorTarget when the component mounts and updates
  useEffect(() => {
    setAnchorTarget(document.getElementById(itemName));
  }, [itemName]);

  // scroll into view on click
  const handleClick = (event) => {
    event.preventDefault();
    anchorTarget.scrollIntoView({ behavior: 'smooth', block: 'start' });
    setIsOpen(false);
  };

  return (
    <li className="text--size-normal">
      <a
        href={`#${itemName}`}
        onClick={handleClick}
        className={active}
        aria-label={`Scroll to ${itemName}`}
      >
        {itemName.charAt(0).toUpperCase() + itemName.slice(1)}
      </a>
    </li>
  );
}
