import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import MenuItem from './MenuItem';

const NavStyles = styled.nav`
  display: flex;
  align-items: baseline;
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  padding: 2rem 4rem;

  @media (max-width: 600px) {
    align-items: center;
  }

  @media (max-width: 1024px) {
    padding: 2rem;
  }

  h1 {
    transition: color 400ms ease-in-out;
    margin-right: auto;
    font-size: 2.4rem;
    z-index: 9999;

    &.dark {
      color: var(--black);
    }
  }

  ul {
    display: flex;
    z-index: 9998;

    @media (max-width: 600px) {
      transition: all 400ms ease-in-out;
      width: 100%;
      height: 100vh;
      background: var(--green);
      position: fixed;
      top: 0;
      left: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      visibility: hidden;
      opacity: 0;
      transform: scale(0.3);

      li {
        font-size: 3rem;
        font-weight: bold;

        &:not(:last-of-type) {
          margin-bottom: 2rem;
        }
      }

      &.open {
        visibility: visible;
        opacity: 1;
        transform: scale(1);
      }
    }

    li:not(:first-of-type) {
      margin-left: 8rem;

      @media (max-width: 1024px) {
        margin-left: 6rem;
      }

      @media (max-width: 768px) {
        margin-left: 4rem;
      }

      @media (max-width: 600px) {
        margin-left: 0;
      }
    }

    a {
      position: relative;

      &:hover::after {
        text-decoration: underline;
        content: '';
        width: 100%;
        height: 2px;
        background: var(--green);
        position: absolute;
        bottom: -0.5rem;
        left: 0;
      }

      @media (max-width: 600px) {
        color: var(--black);
      }
    }
  }

  #menu-toggle {
    height: 2rem;
    width: 2rem;
    display: none;
    z-index: 9999;

    span {
      transition: all 400ms ease-in-out;
      width: 100%;
      height: 2px;
      background: var(--white);

      &:nth-of-type(3) {
        position: absolute;
        top: 9px;
      }
    }

    &.close {
      span {
        background: var(--black);

        &:first-of-type,
        &:last-of-type {
          opacity: 0;
        }

        &:nth-of-type(2) {
          transform: rotate(45deg);
        }

        &:nth-of-type(3) {
          transform: rotate(-45deg);
        }
      }
    }

    @media (max-width: 600px) {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }
`;

const links = ['about', 'services', 'contact'];

export default function Nav() {
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const body = document.querySelector('body');
    if (isOpen) {
      body.classList.add('nav--open');
    } else {
      body.classList.remove('nav--open');
    }
  });

  return (
    <NavStyles>
      <h1
        className={
          isOpen
            ? 'text--strong text--size-medium dark'
            : 'text--strong text--size-medium'
        }
      >
        iPopulate
      </h1>
      <ul className={isOpen ? 'open' : null}>
        {links.map((link, index) => (
          <MenuItem itemName={link} key={index} setIsOpen={setIsOpen} />
        ))}
      </ul>
      <button
        id="menu-toggle"
        type="button"
        className={isOpen ? 'close' : null}
        onClick={handleClick}
      >
        <span />
        <span />
        <span />
        <span />
      </button>
    </NavStyles>
  );
}
