import React from 'react';
import styled from 'styled-components';

const StyledFooter = styled.footer`
  padding: 2rem 4rem;

  @media (max-width: 1024px) {
    padding: 2rem;
  }
`;

export default function Footer() {
  return (
    <StyledFooter>
      <p>&copy; iPopulate {new Date().getFullYear()}</p>
    </StyledFooter>
  );
}
