import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  :root {
    --black: #000;
    --white: #fff;
    --green: #0fffbe;
    --rgb: 15, 255, 190;
    --transition-duration: 500ms;
  }

  html {
    font-size: 10px;
    box-sizing: border-box;
  }

  *, *:before, *:after {
    box-sizing: inherit;
  }

  body {
    font-size: 2rem;

    @media (max-width: 1024px) {
      font-size: 1.6rem;
    }

    &.nav--open {
      height: 100vh;
      overflow: hidden;
    }

    &.overlay-active {
      position: fixed;
      overflow: hidden;
    }
  }

  button {
    border: 0;
    padding: 0;
    background: none;
    color: var(--var);
    position: relative;
    cursor: pointer;

    &:focus {
      outline: 0;  
    }

    &[type="submit"] {
      border-bottom: 2px solid var(--white);
    }

    @media (max-width: 1024px) {
    }
  }

  .gatsby-image-wrapper img[src*=base64\\,] {
    image-rendering: -moz-crisp-edges;
    image-rendering: pixelated;
  }

  img {
    max-width: 100%;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  p {
    white-space: pre-line;
  }

  .underline {
    position: relative;

    &::after {
      content: '';
      width: 100%;
      height: 2px;
      background: var(--green);
      position: absolute;
      bottom: -0.5rem;
      left: 0;
    }
  }

  .modal {
      height: 100vh;
      width: 100vw;
      position: fixed;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: rgba(var(--rgb), 1);
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 9999;

    .content {
      width: 75%;
      max-width: 1020px;
      position: relative;
      background: var(--black);
      color: var(--white);

      &:focus {
        outline: 0;
      }

      .close {
        position: fixed;
        top: 2rem;
        right: 4rem;
        color: var(--black);
        font-weight: bold;

        &.underline::after {
          background: var(--black);
        }
      }

      @media (max-width: 600px) {
        width: 100%;
        height: 100vh;
        overflow: scroll;

        .close {
          color: var(--white);
        } 
      }
    }
  }

  .ReactModal__Body--open {
    height: 100vh;
    overflow: hidden;
  }

  .ReactModal__Overlay {
    opacity: 0;
    transition: opacity var(--transition-duration) ease-in-out;
  }

  .ReactModal__Overlay--after-open{
      opacity: 1;
  }

  .ReactModal__Overlay--before-close{
      opacity: 0;
  }

  fieldset {
    padding: 0;
    margin: 0;
    border: none;

    input {
      width: 100%;
      background: none;
      border: none;
      color: var(--white);

      &:not([type="file"]) {
        padding: 1rem;
        border-bottom: 2px solid var(--white);
      }

      &:focus {
        outline: 0;
      }
    }  
  }

  .Typewriter__cursor {
    color: var(--green);
  }
`;

export default GlobalStyles;
